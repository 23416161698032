import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Calendar,
  Dialog,
  Divider,
  Flex,
  Typography,
} from "@haktos/sugarcoat-ui";
import { useForm, Controller } from "react-hook-form";
import { format } from "date-fns";

import { useBookingWidget } from "../lib/widget.context";
import DatePicker from "./date-picker";
import { CustomInput } from "./custom-ui/input";

type ReservationDetails = {
  billing: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    address: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  };
  checkinDates: {
    startDate: Date;
    endDate: Date;
  } | null;
};

const addFillerDates = (startDate: string, endDate: string) => {
  const dates = [] as any[];
  const currDate = new Date(startDate);
  const lastDate = new Date(endDate);
  while (currDate <= lastDate) {
    dates.push(format(currDate, "yyyy-MM-dd"));
    currDate.setDate(currDate.getDate() + 1);
  }

  return dates;
};

const calculateSumToPay = (dates: any, rates: any, reservationIntent: any) => {
  if (!dates.startDate || !dates.endDate) return null;
  const { adults, children } = reservationIntent;
  const allDates = addFillerDates(dates.startDate, dates.endDate);

  const totalNights = allDates.length - 1;
  const totalGuests = adults + children;

  const total = allDates
    .slice(0, -1)
    .reduce((acc, curr) => acc + rates[curr], 0);

  return {
    dates: allDates,
    totalNights,
    totalGuests,
    total,
  };
};

const ReserveDialog = ({ open, onClose, onSubmit }: any) => {
  const dialogRef = useRef(null);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      billing: {
        firstName: "Bogdan",
        lastName: "Bias",
        email: "bogdan@haktos.com",
        phone: "0784001003",
        address: "Home 1",
        address2: "",
        city: "Timisoara",
        state: "Timis",
        zip: "307160",
        country: "Romania",
      },
      checkinDates: null,
    } as ReservationDetails,
  });

  const [focusedInput, setFocusedInput] = useState(null);
  const [computedValues, setComputedValues] = useState<any>(null);
  const {
    ratePlan,
    rates,
    reservationIntent,
    dates,
    changeDates,
    config,
    makeReservation,
  }: any = useBookingWidget();

  const { styling } = config;
  const defaultStyling = {
    shadow: styling.shadow,
    radius: styling.borderRadius,
    css: { border: `1px solid ${styling.borderColor}` },
  };

  const handleOnClose = () => {
    onClose && onClose();
  };

  const onPreSubmit = async () => {
    const { billing } = getValues();

    await makeReservation({
      dates: computedValues.dates,
      reservationDetails: billing,
    });
    // onSubmit && onSubmit();
  };

  useEffect(() => {
    if (!dates.startDate || !dates.endDate) return;

    setValue("checkinDates", dates);
  }, [dates, setValue]);

  useEffect(() => {
    if (!dates.startDate || !dates.endDate) return;

    const computedValues = calculateSumToPay(dates, rates, reservationIntent);

    setComputedValues(computedValues);
  }, [dates, rates, reservationIntent, ratePlan?.currency?.symbol]);

  return (
    <Dialog open={open} onChange={handleOnClose} overlay={false}>
      {/* @ts-ignore */}
      <Dialog.Overlay css={{ pointerEvents: "none!important" }} />
      {/* @ts-ignore */}
      <Dialog.Content
        title="Reserve now"
        ref={dialogRef}
        footer={
          <Flex justify="between" css={{ width: "100%" }}>
            <Typography.Strong>
              Total:
              {ratePlan?.currency?.symbol}
              {computedValues?.total}
            </Typography.Strong>
            <Button
              size="sm"
              color="neutral"
              variant="text"
              shadow="none"
              onClick={handleOnClose}
            >
              Close
            </Button>
          </Flex>
        }
      >
        <Box
          css={{
            minWidth: "450px",
            minHeight: "550px",
          }}
        >
          <Flex
            as="form"
            direction="column"
            gap="4"
            items="stretch"
            css={{ height: "100%" }}
            onSubmit={handleSubmit(onPreSubmit)}
          >
            <Controller
              name="checkinDates"
              control={control}
              render={({ field: { onChange, value, ...field } }) => (
                <DatePicker
                  startDate={value?.startDate!}
                  endDate={value?.endDate!}
                  onDateChange={changeDates}
                  numberOfMonths={1}
                  showPriceInfo
                  autoFocusEndDate
                  enableOutsideDays
                  block
                  focusedInput={focusedInput}
                  onFocusChange={(focusedInput: any) => {
                    setFocusedInput(focusedInput);
                  }}
                />
              )}
            />
            <Flex justify="between">
              <Typography.Text>
                {ratePlan?.roomType?.title}, {computedValues?.totalNights}{" "}
                nights, {ratePlan?.currency?.symbol}
                {computedValues?.total}
              </Typography.Text>
              <Typography.Text>
                {reservationIntent.adults} adults, {reservationIntent.children}{" "}
                children
              </Typography.Text>
            </Flex>
            <Divider />
            <Flex gap="4">
              <CustomInput
                placeholder="First Name"
                fullWidth
                state={errors.billing?.firstName ? "invalid" : "default"}
                aria-invalid={errors.billing?.firstName ? "true" : "false"}
                {...defaultStyling}
                {...register("billing.firstName", { required: true })}
              />
              <CustomInput
                placeholder="Last Name"
                fullWidth
                state={errors.billing?.lastName ? "invalid" : "default"}
                aria-invalid={errors.billing?.lastName ? "true" : "false"}
                {...defaultStyling}
                {...register("billing.lastName", { required: true })}
              />
            </Flex>
            <CustomInput
              placeholder="Email"
              type="email"
              fullWidth
              state={errors.billing?.email ? "invalid" : "default"}
              aria-invalid={errors.billing?.email ? "true" : "false"}
              {...defaultStyling}
              {...register("billing.email", {
                required: true,
                pattern: /^\S+@\S+$/i,
              })}
            />
            <CustomInput
              placeholder="Phone number"
              type="phone"
              fullWidth
              state={errors.billing?.phone ? "invalid" : "default"}
              aria-invalid={errors.billing?.phone ? "true" : "false"}
              {...defaultStyling}
              {...register("billing.phone", {
                required: true,
              })}
            />
            <CustomInput
              placeholder="Address"
              fullWidth
              state={errors.billing?.address ? "invalid" : "default"}
              aria-invalid={errors.billing?.address ? "true" : "false"}
              {...defaultStyling}
              {...register("billing.address", {
                required: true,
              })}
            />
            <CustomInput
              placeholder="Address 2"
              fullWidth
              state={errors.billing?.address2 ? "invalid" : "default"}
              aria-invalid={errors.billing?.address2 ? "true" : "false"}
              {...defaultStyling}
              {...register("billing.address2")}
            />
            <Flex gap="4">
              <CustomInput
                placeholder="City"
                fullWidth
                state={errors.billing?.city ? "invalid" : "default"}
                aria-invalid={errors.billing?.city ? "true" : "false"}
                {...defaultStyling}
                {...register("billing.city", {
                  required: true,
                })}
              />
              <CustomInput
                placeholder="State"
                fullWidth
                state={errors.billing?.state ? "invalid" : "default"}
                aria-invalid={errors.billing?.state ? "true" : "false"}
                {...defaultStyling}
                {...register("billing.state", {
                  required: true,
                })}
              />
            </Flex>
            <Flex gap="4">
              <CustomInput
                placeholder="Zip"
                fullWidth
                state={errors.billing?.zip ? "invalid" : "default"}
                aria-invalid={errors.billing?.zip ? "true" : "false"}
                {...defaultStyling}
                {...register("billing.zip", {
                  required: true,
                })}
              />
              <CustomInput
                placeholder="Country"
                fullWidth
                state={errors.billing?.country ? "invalid" : "default"}
                aria-invalid={errors.billing?.country ? "true" : "false"}
                {...defaultStyling}
                {...register("billing.country", {
                  required: true,
                })}
              />
            </Flex>
            <Button
              size="md"
              color="neutral"
              radius={styling.borderRadius}
              shadow={styling.shadow}
              disabled={isSubmitting}
              block
            >
              {isSubmitting ? "Please wait..." : "Reserve now"}
            </Button>
          </Flex>
        </Box>
      </Dialog.Content>
    </Dialog>
  );
};

export default ReserveDialog;
