import { ControlGroup, styled } from "@haktos/sugarcoat-ui";

export const CustomStyledCG = styled(ControlGroup, {
  borderRadius: "0!important",
  variants: {
    shadow: {
      none: {
        boxShadow: "none!important",
      },
      xs: {
        boxShadow: "$xs!important",
      },
      sm: {
        boxShadow: "$sm!important",
      },
      md: {
        boxShadow: "$md!important",
      },
      lg: {
        boxShadow: "$lg!important",
      },
    },
  },
});
