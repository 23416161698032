import { useState } from "react";

import { Button, Dropdown, Flex, Typography } from "@haktos/sugarcoat-ui";
import { useBookingWidget } from "../lib/widget.context";
import DatePicker from "./date-picker";
import ReserveDialog from "./reserve-dialog";
import {
  CustomStyleDropdownContent,
  CustomStyledDropdownItem,
} from "./custom-ui/dropdown";
import { CustomStyledCG } from "./custom-ui/control-group";
import { CustomNumberInput } from "./custom-ui/input";

export const Widget = () => {
  const [focusedInput, setFocusedInput] = useState(null);
  const [reserveDialogOpen, setReserveDialogOpen] = useState(false);

  const {
    ratePlan,
    reservationIntent,
    dates,
    availability,
    rates,
    loading,
    changeDates,
    setReservationIntent,
    config,
  }: any = useBookingWidget();

  const { styling } = config;

  const handleDateChange = ({ startDate, endDate }: any) => {
    changeDates({ startDate, endDate });
  };

  const handleReserveNow = () => {
    setReserveDialogOpen(true);
  };

  const calculateTotalGuestsIntent = () => {
    const totalGuests = reservationIntent.adults + reservationIntent.children;
    if (totalGuests === 0) return "Guests";
    if (totalGuests === 1) return "1 guest";
    return `${totalGuests} guests`;
  };

  return (
    <>
      <ReserveDialog
        open={reserveDialogOpen}
        onClose={() => setReserveDialogOpen(false)}
        onSubmit={() => setReserveDialogOpen(false)}
      />
      <Flex>
        <DatePicker
          startDate={dates?.startDate}
          endDate={dates?.endDate}
          onDateChange={handleDateChange}
          numberOfMonths={1}
          showPriceInfo
          showClearDates
          reopenPickerOnClearDates
          focusedInput={focusedInput}
          autoFocusEndDate
          enableOutsideDays
          onFocusChange={(focusedInput: any) => {
            setFocusedInput(focusedInput);
          }}
        />
        <Dropdown>
          <Dropdown.Trigger
            variant="outline"
            color="neutral"
            shadow={styling.shadow}
            radius={styling.borderRadius}
            css={{
              border: "none",
              borderTop: `1px solid ${styling.borderColor}`,
              borderBottom: `1px solid ${styling.borderColor}`,
            }}
          >
            {calculateTotalGuestsIntent()}
          </Dropdown.Trigger>
          <CustomStyleDropdownContent
            arrow
            css={{
              border: `1px solid ${styling.borderColor}`,
              backgroundColor: styling.backgroundColor,
              [`${CustomStyledDropdownItem}`]: {
                borderRadius: styling.borderRadius,
                backgroundColor: styling.backgroundColor,
                "&:hover": {
                  backgroundColor: styling.highlightColor,
                },
              },
            }}
          >
            <CustomStyledDropdownItem>
              <CustomStyledCG border shadow={styling.shadow} label="Adults">
                <Button
                  variant="outline"
                  color="neutral"
                  disabled={reservationIntent.adults === 0}
                  onClick={(e) => {
                    e.preventDefault();
                    setReservationIntent({
                      ...reservationIntent,
                      adults: reservationIntent.adults - 1,
                    });
                  }}
                >
                  -
                </Button>
                <CustomNumberInput readonly value={reservationIntent.adults} />
                <Button
                  variant="outline"
                  color="neutral"
                  disabled={
                    reservationIntent.adults === ratePlan?.roomType?.occAdults
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setReservationIntent({
                      ...reservationIntent,
                      adults: reservationIntent.adults + 1,
                    });
                  }}
                >
                  +
                </Button>
              </CustomStyledCG>
            </CustomStyledDropdownItem>
            <CustomStyledDropdownItem>
              <CustomStyledCG border shadow={styling.shadow} label="Children">
                <Button
                  variant="outline"
                  color="neutral"
                  disabled={reservationIntent.children === 0}
                  onClick={(e) => {
                    e.preventDefault();
                    setReservationIntent({
                      ...reservationIntent,
                      children: reservationIntent.children - 1,
                    });
                  }}
                >
                  -
                </Button>
                <CustomNumberInput
                  readonly
                  value={reservationIntent.children}
                />
                <Button
                  variant="outline"
                  color="neutral"
                  disabled={
                    reservationIntent.children ===
                    ratePlan?.roomType?.occChildren
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setReservationIntent({
                      ...reservationIntent,
                      children: reservationIntent.children + 1,
                    });
                  }}
                >
                  +
                </Button>
              </CustomStyledCG>
            </CustomStyledDropdownItem>
          </CustomStyleDropdownContent>
        </Dropdown>
        <Button
          variant="outline"
          color="neutral"
          shadow={styling.shadow}
          radius={styling.borderRadius}
          onClick={handleReserveNow}
          css={{
            border: `1px solid ${styling.borderColor}`,
          }}
          disabled={
            !dates.startDate ||
            !dates.endDate ||
            reservationIntent.adults === 0 ||
            loading
          }
        >
          Reserve now
        </Button>
      </Flex>
    </>
  );
};
