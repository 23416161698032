const client = async (endpoint: string, { body, ...customConfig } = {}) => {
  const headers = {
    // "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
  };

  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      // @ts-ignore
      ...customConfig.headers,
    },
  };

  // @ts-ignore
  config.body = JSON.stringify(body);

  return window.fetch(endpoint, config).then(async (r) => {
    if (r.status === 204) return "";

    const data = await r.json();

    if (r.ok) {
      return data;
    } else {
      return Promise.reject({ ...data });
    }
  });
};
export { client };
