import {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { format, addMonths, endOfMonth } from "date-fns";

import { client } from "./api-client";
import { BookingEngineConfig } from "../types/general.types";

const API_URL = "https://hub.klarq.local/api/v1";

interface WidgetProviderProps {
  children: React.ReactNode;
  initialConfig?: BookingEngineConfig;
}

const DEFAULT_DATES = {
  startDate: null,
  endDate: null,
};
const WidgetContext = createContext(null);

const widgetReducer = (
  state: any,
  action: {
    type: string;
    payload?: any;
  }
) => {
  const defaultStyling = {
    borderColor: "#000000",
    borderRadius: "none",
    shadow: "none",
    primaryColor: "#222",
    secondaryColor: "#555",
    backgroundColor: "#fff",
    highlightColor: "#f9f9f9",
  };

  switch (action.type) {
    case "SET_LOADING": {
      return { ...state, loading: action.payload };
    }
    case "SET_PROPERTY":
      return { ...state, property: action.payload, loading: false };
    case "SET_DATES":
      return { ...state, dates: action.payload };
    case "SET_INTENT":
      return { ...state, reservationIntent: action.payload };
    case "UPDATE_AVAILABILITY":
      return { ...state, availability: action.payload };
    case "UPDATE_RATES":
      return { ...state, rates: action.payload };
    case "UPDATE_RATE_PLAN":
      return {
        ...state,
        ratePlan: {
          ...state.ratePlan,
          ...action.payload,
        },
      };
    case "UPDATE_RATES_AND_AVAILABILITY":
      return {
        ...state,
        availability: action.payload.availability,
        rates: action.payload.rates,
      };
    case "SET_CONFIG":
      const { ratePlan, ...payload } = action.payload;
      const updateConfig = {
        ...state.config,
        ...payload,
      };
      return { ...state, ratePlan, config: updateConfig };
    case "INIT":
      const { property, siteKey, config } = action.payload;

      return {
        ...state,
        property,
        siteKey,
        config: {
          styling: {
            hasCustomStyling: config?.styling ? true : false,
            ...defaultStyling,
            ...config?.styling,
          },
        },
        loading: false,
      };
    default:
      return state;
  }
};

export const useBookingWidget = () => {
  const context = useContext(WidgetContext);
  if (!context) {
    throw new Error("useBookingWidget must be used within a WidgetProvider");
  }
  return context;
};

export const WidgetProvider: React.FC<WidgetProviderProps> = ({
  children,
  initialConfig,
}: any) => {
  const [state, dispatch] = useReducer(widgetReducer, {
    siteKey: null,
    property: null,
    ratePlan: null,
    rates: null,
    availability: null,
    dates: DEFAULT_DATES,
    loading: true,
    reservationIntent: {
      adults: 2,
      children: 0,
      infants: 0,
    },
  });

  const fetchWidgetConfig = useCallback(async () => {
    const { data } = await client(
      `${API_URL}/widget-config?siteKey=${state.siteKey}}`
    );

    dispatch({ type: "SET_CONFIG", payload: data });
  }, [state.siteKey]);

  const fetchRatesAndAvailability = useCallback(async () => {
    if (!state.property?.id || !state.ratePlan?.id) return;
    const startDate = format(new Date(), "yyyy-MM-dd");
    const endDate = format(
      endOfMonth(addMonths(new Date(startDate), 3)),
      "yyyy-MM-dd"
    );
    const propertyId = state.property.id;
    const ratePlanId = state.ratePlan.id;
    const { data: availability } = await client(
      `${API_URL}/availability?propertyId=${propertyId}&startDate=${startDate}&endDate=${endDate}`
    );

    const {
      data: { rates, ...ratePlan },
    } = await client(
      `${API_URL}/rates?propertyId=${propertyId}&ratePlan=${ratePlanId}&startDate=${startDate}&endDate=${endDate}`
    );
    updateRatesAndAvailability({ availability, rates: rates });
    dispatch({ type: "UPDATE_RATE_PLAN", payload: ratePlan });
  }, [state.property, state.ratePlan]);

  const setLoading = (value: any) => {
    dispatch({ type: "SET_LOADING", payload: value });
  };

  const changeDates = ({ startDate, endDate }: any) => {
    dispatch({
      type: "SET_DATES",
      payload: {
        startDate,
        endDate,
      },
    });
  };

  const setReservationIntent = (value: any) => {
    dispatch({ type: "SET_INTENT", payload: value });
  };

  // const updateAvailability = (availability: any) => {
  //   dispatch({ type: "UPDATE_AVAILABILITY", payload: availability });
  // };

  // const updateRates = (availability: any) => {
  //   dispatch({ type: "UPDATE_RATES", payload: availability });
  // };

  const updateRatesAndAvailability = (data: any) => {
    dispatch({ type: "UPDATE_RATES_AND_AVAILABILITY", payload: data });
  };

  const makeReservation = useCallback(
    async ({ dates, reservationDetails }: any) => {
      if (!state.property?.id || !state.ratePlan?.id) return;

      const propertyId = state.property.id;
      const ratePlanId = state.ratePlan.id;
      console.log("dates", dates);
      console.log("reservationDetails", reservationDetails);
      const { data } = await client(`${API_URL}/booking`, {
        body: {
          propertyId,
          ratePlanId,
          dates,
          reservationDetails,
        },
      });
      // updateRatesAndAvailability({ availability, rates: rates });
      // dispatch({ type: "UPDATE_RATE_PLAN", payload: ratePlan });
    },
    [state.property, state.ratePlan]
  );

  // useEffect(() => {
  //   const localStorageData = localStorage.getItem("travelizr-hub-data");
  //   if (localStorageData) {
  //     dispatch({ type: "INIT", payload: JSON.parse(localStorageData) });
  //   }
  // }, []);

  useEffect(() => {
    if (!state.siteKey) return;
    async function fetch() {
      await fetchWidgetConfig();
    }

    fetch();
  }, [state.siteKey, fetchWidgetConfig]);

  useEffect(() => {
    if (state.availability || !state.siteKey) return;
    async function fetch() {
      await fetchRatesAndAvailability();
    }

    fetch();
  }, [
    state.siteKey,
    state.dates,
    state.availability,
    fetchRatesAndAvailability,
  ]);

  useEffect(() => {
    if (!initialConfig) return;
    dispatch({
      type: "INIT",
      payload: initialConfig,
    });
  }, [initialConfig]);

  const data = useMemo(() => {
    return {
      ...state,
      changeDates,
      setLoading,
      setReservationIntent,
      makeReservation,
    };
  }, [state, makeReservation]);

  if (!state.property?.id) {
    return;
  }

  if (!initialConfig) {
    console.error("No initial config provided");
    return;
  }

  if (!initialConfig.property) {
    console.error("No initial property provided");
    return;
  }

  if (!initialConfig.siteKey) {
    console.error("No initial siteKey provided");
    return;
  }

  return (
    // @ts-ignore
    <WidgetContext.Provider value={data}>{children}</WidgetContext.Provider>
  );
};
