import React from "react";
import { Flex, styled } from "@haktos/sugarcoat-ui";

const StyledWidgetWrapper = styled(Flex, {
  background: "$white",
  px: "$8",
  py: "$4",
});
const WidgetWrapper = ({ children }: any) => {
  return (
    <StyledWidgetWrapper direction="column" gap="4">
      {children}
    </StyledWidgetWrapper>
  );
};

export default WidgetWrapper;
