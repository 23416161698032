import { Dropdown, styled } from "@haktos/sugarcoat-ui";

export const CustomStyleDropdownContent = styled(Dropdown.Content, {
  borderRadius: "0!important",
});

export const CustomStyledDropdownItem = styled(Dropdown.Item, {
  ".ValueInput": {
    maxWidth: "40px",
  },
});
