import React from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import { DateRangePicker } from "react-dates";
import { styled } from "@haktos/sugarcoat-ui";
import moment from "moment";
import { useBookingWidget } from "../lib/widget.context";

const StyledDayBox = styled("div", {});

const StyledDatePickerWrapper = styled("div", {
  ".CalendarDay": {
    verticalAlign: "middle",
  },

  ".DateRangePickerInput__withBorder": {
    borderRadius: "$$calBorderRadius",
    border: "1px solid $$calBorderColor",
  },

  ".DayPicker__withBorder": {
    borderRadius: "$$calBorderRadius",
    boxShadow: `0 0 0 1px $$calBorderColor`,
  },

  ".DateInput_fangStroke": {
    stroke: "$$calBorderColor",
  },

  ".DayPicker_transitionContainer": {
    borderRadius: "$$calBorderRadius",
  },

  ".DateInput_input__focused": {
    borderBottomColor: "$$calPrimaryColor",
  },

  ".CalendarDay__default:hover": {
    background: "$$calPrimaryColor",
  },

  ".CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover":
    {
      background: "$$calPrimaryColor",
      borderColor: "$$calPrimaryColor",
    },

  // .CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {}
  ".CalendarDay__selected_span": {
    background: "$$calPrimaryColor",
    borderColor: "$$calPrimaryColor",
  },

  ".CalendarDay__hovered_span, .CalendarDay__hovered_span:hover": {
    background: "$$calPrimaryColor",
    borderColor: "$$calPrimaryColor",
    color: "$neutral",
  },

  ".DayPickerKeyboardShortcuts_show__bottomRight::before": {
    borderRightColor: "$$calPrimaryColor",
  },
});

const DatePicker = ({
  startDate,
  endDate,
  onDateChange,
  showPriceInfo = false,
  ...otherProps
}: any) => {
  const {
    availability,
    rates,
    ratePlan,
    config: { styling },
  }: any = useBookingWidget();

  const isDayBlocked = (day: any) => {
    const dayString = moment(day).format("yyyy-MM-DD");
    if (!availability) return false;

    if (typeof availability[dayString] !== "undefined") {
      return !availability[dayString];
    }

    return false;
  };

  return (
    <StyledDatePickerWrapper
      css={{
        $$calPrimaryColor: styling.primaryColor,
        $$calSecondaryColor: styling.secondaryColor,
        $$calBorderColor: styling.borderColor,
        $$calBorderRadius: styling.borderRadius,
        $$calShadow: styling.shadow,
      }}
    >
      {/* @ts-ignore */}
      <DateRangePicker
        startDateId="startDate"
        endDateId="endDate"
        startDate={startDate}
        endDate={endDate}
        onDatesChange={onDateChange}
        isDayBlocked={isDayBlocked}
        minimumNights={ratePlan?.minimumStay || 1}
        hideKeyboardShortcutsPanel
        daySize={45}
        renderDayContents={(day: moment.Moment) => {
          if (showPriceInfo) {
            const dayString = day.format("yyyy-MM-DD");

            if (rates && typeof rates[dayString] !== "undefined") {
              return (
                <StyledDayBox>
                  {day.format("DD")}
                  <br />
                  <span
                    style={{
                      color: styling.secondaryColor,
                      fontSize: "0.8rem",
                    }}
                  >
                    {rates[dayString]} {ratePlan?.currency?.symbol}
                  </span>
                </StyledDayBox>
              );
            }
          }

          return <StyledDayBox>{day.format("DD")}</StyledDayBox>;
        }}
        {...otherProps}
      />
    </StyledDatePickerWrapper>
  );
};

export default DatePicker;
